import React from 'react'
import { ReactComponent as Casket } from '../assets/green-casket.svg'

const RoadmapSection = () => {
  return (
    <section className="w-full relative max-w-5xl mx-auto py-16 px-4 space-y-8 text-white" id="roadmap">
      <h2 className="font-avander font-medium text-5xl md:text-7xl text-center">Roadmap</h2>
      <section>
        <article className="flex md:space-x-20 border-b-4 border-white pb-28">
          <div className="hidden md:block w-3 h-[1720px] bg-white mt-40"></div>
          <div className="flex-1 space-y-8">
            <h3 className="font-avander text-4xl uppercase pb-4 md:pb-8">Phase 1</h3>
            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">Degen Time:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[16rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">1</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                This period we look to organize and collaborate using strategic partnerships with distinguished brands and/or projects  across many avenues within the NFT community, celebrities, artists, influencers and other NFT projects.
              </p>
            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">Airdrops:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[26rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">2</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                During Mint Phase, the Aptos Skulls team will airdrop 25 free Aptos Skulls NFTs among selected supporters. Stay tuned through our Discord activities to enter.
              </p>
            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">Aptos Store:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[35rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">3</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                Aptos Skulls streetwear collaboration will be launched for our unique community to set us apart. This will include Hoodies, T-shirts, art collectibles and so much more!
              </p>
            </article>

            <h3 className="font-avander text-4xl uppercase pb-4 md:pb-8">Phase 2</h3>

            <article className="space-y-4">
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[53rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">4</p>
              </div>

            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">NOT SO DEGEN LAUNCHPAD:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[68.5rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">5</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                Aptos Skulls holders will gain early access to new and upcoming NFT projects, Whitelist spots, even FREE NFTs. Depending on the upcoming NFT projects we have coming in the future, this may be a monthly, or even a weekly event!
              </p>
            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">GEN 2 COLLECTION UTILITY:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[79rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">6</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                Our next collection drop will be bigger than big! Some of our holders will have early whitelist spots and even FREE NFTs for this collection. This may or not be already done?
              </p>
            </article>

            <h3 className="font-avander text-4xl uppercase pb-4 md:pb-8">Phase 3</h3>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">Aptos PASS:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[97rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">7</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                The Aptos Pass will be created in order to allow the new community to decide the direction of Aptos Skulls, its ecosystem and its future to continue developing Web3 and the NFTs. A FULL secondary sales percentage will go into the community vault which will be used in any area the ecosystem sees fit to continue expanding Aptos Skulls. Each holder will be granted voting power within the ecosystem, privileges and exclusive access to anything related to our project.
              </p>
            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">WORLD OF SKULLS:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[112rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">8</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                Our game plan here is to exclusively develop and maintain a WEB3 environment where anything and everything related to our ecosystem can come together to further expand our ecosystem, interact with decentralised works, and further make an impact on the WEB3
              </p>
            </article>

            <article className="space-y-4">
              <h2 className="uppercase font-bold text-2xl border-b border-white relative">VIDEO GAME UTILITY:</h2>
              <div className="relative flex justify-center md:absolute md:left-[2px] md:top-[124rem]">
                <Casket />
                <p className="text-black absolute md:left-3 top-4 text-3xl">9</p>
              </div>
              <p className="font-semibold text-xl lg:pr-6">
                As true believers in the blockchain and everything it has to offer, we would like to introduce the world’s first holder-founded P2E video game. What does this mean? From beginning to end, holders will have the opportunity to join us on the journey of designing, developing and curating a P2E ecosystem that will continuously reward holders and users. Best of all? Everything and anything inside the ecosystem will be exclusively available for Aptos Skull holders first . We aim to introduce the world's first IOS and Android based video game. Here, we will empower our users with NFT based collectables, ownerships and a foundation around the P2E environment. Whether it’s levelling up, value of collectibles and/or an earning style - this will be rewarded to Aptos Skull holders in a tokenized format allowing them to do it for APTOS.

              </p>
            </article>
          </div>
        </article>
      </section>
    </section>
  )
}

export default RoadmapSection
